import React from "react"
import { Box, Container, Heading } from "@chakra-ui/react"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import BrandItem from "@app/components/Sections/BrandCarousel/BrandItem"

SwiperCore.use([Pagination])

type Props = GatsbyTypes.SanitySectionBrandCarousel & AnalyticProps

const BrandCarousel: React.FC<Props> = ({ title, brands, innerRef, handleTrackingClick, tag }) => {
  const id = Math.random().toString(16).slice(2)

  return brands?.length ? (
    <Box as="section" ref={innerRef} bg="background.beige">
      <Container maxW="container.4xl" px={{ base: "4", md: "8", lg: "20" }} py={{ base: "8", lg: "22.5" }}>
        {title && (
          <Heading as={tag} size="h3" color="typography.headlines900" mb={{ base: "7", lg: "12" }} textAlign="center">
            {title}
          </Heading>
        )}

        <Box
          sx={{
            ".swiper-slide": { bg: "transparent" },
            ".swiper-pagination": { d: "flex", justifyContent: "center", gap: "2.5", mt: { base: "7", lg: "12" } },
            ".swiper-pagination-bullet": {
              d: "inline-block",
              w: "1.5",
              h: "1.5",
              borderRadius: "full",
              bg: "border.default",
              cursor: "pointer",
              transition: "background 0.2s ease",
            },
            ".swiper-pagination-bullet.swiper-pagination-bullet-active": {
              bg: "brand.primary",
            },
          }}
        >
          <Swiper
            lazy
            loop
            simulateTouch
            slidesPerView={3}
            spaceBetween={16}
            pagination={{ clickable: true, el: `#pagination-${id}` }}
            breakpoints={{
              500: { slidesPerView: 5 },
              750: { slidesPerView: 6 },
              1024: { slidesPerView: 5, spaceBetween: 20 },
              1350: { slidesPerView: 6, spaceBetween: 48 },
              1800: { slidesPerView: 7, spaceBetween: 48 },
            }}
          >
            {brands.map((brand, index) => {
              if (!brand) return null

              return (
                <SwiperSlide key={index}>
                  <BrandItem key={index} brand={brand} handleTrackingClick={handleTrackingClick} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <Box id={`pagination-${id}`} className="swiper-pagination" />
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(BrandCarousel))
